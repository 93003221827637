import React from "react";
import { BrowserRouter as Router, Route, Routes} from "react-router-dom";

import Header from "./components/Header";
import Footer from "./components/Footer";
import Home from "./views/Home";
import history from "./utils/history";
import { SignUp, Login } from "./components/LoginSignUp";
import EligibilityCheck from "./components/EligibilityCheck";
import Ineligible from "./content/Ineligible";
import SysError from "./content/SysError";
import SessionTimeout from "./content/SessionTimeout";
import PageNotFound from "./content/PageNotFound";
import Aside from "./components/Aside";
import ServiceUnavailable from "./content/ServiceUnavailable";
// import SocialMedia from "./components/SocialMedia";
// import SectionNavigation from "./components/SectionNavigation";

// styles
import "./App.css";

// fontawesome
import initFontAwesome from "./utils/initFontAwesome";
initFontAwesome();

/**
 * The main application
 * @returns the app
 */
const App = () => {  
  return (
    <>      
      <Router history={history}>

        <Header />

        <div className="container-fluid qg-site-width">

          <div id="qg-content">
            <div id="qg-three-col" className="row wide">

              <div id="qg-primary-content" role="main">

                <Routes>
                  <Route path="/" element={<Home/>}/>
                  <Route path="*" element={<PageNotFound />} />

                  <Route path='/signUp' element={<SignUp/>} />
                  <Route path='/login' element={<Login/>} />
                  <Route path="/EligibilityCheck" element={<EligibilityCheck/>} />
                  <Route path="/Ineligible" element={<Ineligible/>} />

                  <Route path="/SysError" element={<SysError/>} />
                  <Route path="/SessionTimeout" element={<SessionTimeout/>} />
                  <Route path="/service-unavailable" element={<ServiceUnavailable />} />
                </Routes>

                {/* <SocialMedia /> */}

              </div>

              <Aside />

              {/* <SectionNavigation /> */}


            </div>
          </div>
        </div>
      </Router>
      <Footer />

    </>
  );
};

export default App;
